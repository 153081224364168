import React from "react";
import './RandomGrid.scss'
import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import classNames from "classnames";
import {Show} from "../Util/Show";

export function GridItem({gridArea, data, lastRow, firstColumn}) {
    function getLinkTarget(data) {
        if (data.urlIsExternal === true) {
            return ({target: "_blank"});
        }
        return {};
    }

    return (
        <Card className={classNames("gridItem", "border-end-0", {"border-bottom-0": !lastRow, "border-start-0": firstColumn})}
              style={{gridArea: gridArea}}>
            <Card.Title className='m-3'>
                {data.title}
                <Show>
                    <Show.When isTrue={!!data.url}>
                        <Link to={data.url} {...getLinkTarget(data)}>
                            <FontAwesomeIcon icon={faArrowRight} style={{marginLeft: '10px', color: 'black'}}/>
                        </Link>
                    </Show.When>
                </Show>
            </Card.Title>
        </Card>
    )
}
