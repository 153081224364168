import "./RandomGrid.scss"
import {Fragment} from "react";

import * as changeCase from 'change-case';
import {GridItem} from "./GridItem";
import {useGridLayout} from "./useGridLayout";
import {Each} from "../Util/Each";


export function RandomGrid({gridElements}) {
    const gridLayout = useGridLayout(gridElements);

    function createGridTemplateAreas() {
        return gridLayout.values.map(v => `'${v.flatMap(c => changeCase.snakeCase(c.title)).join(' ')}'`).join('');
    }

    function isLastRow(index) {
        return (index + 1) === gridLayout.rows;
    }

    function isFirstColumn(index) {
        return index === 0;
    }

    return (
        <Fragment>
            <div className={"randomGrid"} style={{gridTemplateAreas: createGridTemplateAreas()}}>
                <Each of={gridLayout.values} render={(row, rowIndex) =>
                    <Each of={row} render={(column, columnIndex) =>
                        <GridItem gridArea={`${changeCase.snakeCase(column.title)}`}
                                  data={column}
                                  lastRow={isLastRow(rowIndex)}
                                  firstColumn={isFirstColumn(columnIndex)}
                        />
                    }/>
                }/>
            </div>
        </Fragment>

    )
}
