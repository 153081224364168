export const PAGES = {
    PersonList: '/',
    PersonListByFuneralHome: '/uitvaartcentrum/:alias',
    PersonDetail: '/:name',
    CondolenceFlowers: '/:name/flowers',
    ParticipationCard: '/:name/participation-card',
    ParticipationCardShop: '/:name/participation-card/shop',
    FuneralFlower: '/:name/flowers/funeral-flower',
    MourningVisitPage: '/:name/mourning-visit'
}
