import {useMemo} from "react";

export const useGridLayout = (values) =>
    useMemo(() => {
        const maxColumns = values.length < 3 ? values.length : 3;
        const shuffled = values
        let x = [];
        let y = [];
        for (let i = 0; i < shuffled.length; i++) {
            if (i % maxColumns === 0 && i !== 0) {
                y.push(x);
                x = [];
            }
            x.push(shuffled[i]);
            if (i === shuffled.length - 1) {
                y.push(x);
            }
        }

        if (maxColumns * y.length % values.length !== 0) {
            let amountToChange = maxColumns * y.length % values.length;
            const copyX = y[y.length - 2];
            let possibleValues = [...copyX];
            let valuesToStretch = [];
            while (amountToChange !== 0) {
                const index = Math.floor(Math.random() * possibleValues.length);
                const valueToStretch = possibleValues[index];
                const xIndex = copyX.indexOf(valueToStretch);
                valuesToStretch.push({valueToStretch, xIndex});
                possibleValues.splice(index, 1);
                amountToChange -= 1;
            }
            valuesToStretch = valuesToStretch.sort((a, b) => a.xIndex - b.xIndex);
            let copyXLast = y[y.length - 1];
            for (const v of valuesToStretch) {
                copyXLast = [...copyXLast.slice(0, v.xIndex), v.valueToStretch, ...copyXLast.slice(v.xIndex)];
            }
            y[y.length - 1] = copyXLast;
        }

        return {
            values: y,
            columns: maxColumns,
            rows: y.length
        };
    }, [values])