// import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import React, {Fragment} from "react";
import './RandomGrid.scss'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CondolencesForm from "../Condolences/CondolencesForm";
import {Card} from "react-bootstrap";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {RandomGrid} from "./RandomGrid";
import Arrow from "../../assets/images/pijlNav.svg"

export function CondolenceGrid({gridElements, dossierNumber, isCondolencePublic}) {

    function getLinkTarget(data) {
        if (data.urlIsExternal === true) {
            return ({target: "_blank"});
        }
        return {};
    }

    function otherItemWrapper() {
        const elements = gridElements.elementsExceptCondolence;
        if (elements.length === 0) {
            return <Fragment/>
        }
        let children = elements.map((item, index) => (
            <Fragment key={index}>
                {!!item.url && <Link className={"anchor-no-decoration"} to={item.url} {...getLinkTarget(item)}>
                    <Card className={classnames("border-end-0", {"border-bottom-0": (elements.length > index + 1)})}
                          style={{height: `${100 / elements.length}%`}}>
                        <Card.Title className='m-3'>
                            {item.title}
                        </Card.Title>
                        <Card.Footer className={"mt-auto border-0 bg-white"}>
                            <img src={Arrow} style={{width: "50px", marginBottom: "20px"}} alt={"Arrow"}/>
                        </Card.Footer>
                    </Card>
                </Link>}
                {!item.url &&
                    <Card onClick={() => item.onClickHandler()} className={classnames("border-end-0", {"border-bottom-0": (elements.length > index + 1)}, "clickable")}
                          style={{height: `${100 / elements.length}%`}}>
                        <Card.Title className='m-3'>
                            {item.title}
                        </Card.Title>
                        <Card.Footer className={"mt-auto border-0 bg-white"}>
                            <img src={Arrow} style={{width: "50px", marginBottom: "20px"}} alt={"Arrow"}/>
                        </Card.Footer>
                    </Card>
                }
            </Fragment>

        ));
        return (<Col md={4} className={"p-0"}>
            {children}
        </Col>);
    }

    return (
        <Fragment>
            <Row className={classnames("mt-5", "mb-5", "justify-content-center")}>
                {
                    !!gridElements.condolence &&
                    <Fragment>
                        <Col md={7} className={"p-0"}>
                            <Card className={"h-100 border-end-0 border-start-0"}>
                                <CondolencesForm dossierNumber={dossierNumber} isCondolencePublic={isCondolencePublic}/>
                            </Card>
                        </Col>
                        {otherItemWrapper()}
                    </Fragment>
                }
                {!gridElements.condolence && gridElements.elementsExceptCondolence.length > 0 && (
                    <RandomGrid gridElements={gridElements.elementsExceptCondolence}/>
                )}
            </Row>
        </Fragment>
    )
}
